import { createSlice } from '@reduxjs/toolkit';

const userSettingsSlice = createSlice({
  name: 'userSettings',
  initialState: { currentTheme: 'defaultAlgorithm' },
  reducers: {
    switchTheme: (state, action) => {
      state.currentTheme = action.payload;
    },
  },
});

export const { switchTheme } = userSettingsSlice.actions;
export default userSettingsSlice.reducer;