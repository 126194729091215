import React from 'react';
import { Row, Col, Card, Typography, List, Space} from 'antd';

import { useSelector } from 'react-redux';

import UserProfile from '../../components/UserProfile';
import ChangePasword from '../../components/ChangePassword';
import Address from '../../components/Address';
import CreditCard from '../../components/CreditCard';

const { Title, Text } = Typography;

const ProfilePage = () => {
    const creditCards = useSelector(state => state.user.creditCards);
    const address = useSelector((state) => state.user.address);

    return (
    <>
        <Title level={2}>My Profile</Title>
        <Card>
            <Space size="middle" direction="vertical" style={{ display: 'flex' }}>
                <UserProfile title="Profile"></UserProfile>
                <ChangePasword title="Change Password"></ChangePasword>
                <Address title="Address" address={address}></Address>
                <List dataSource={creditCards} 
                    itemLayout="vertical"
                    bordered
                    renderItem={(card) => (
                <List.Item key={card.card_id}>
                    <CreditCard title="Credit Cards" cardId={card.card_id}></CreditCard>
                </List.Item>
                )} />
            </Space>
        </Card>    
    </>
    );
};

export default ProfilePage;