import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

// Translation files are located @ ./public/locales/{lang}/translation.json

i18next
  .use(initReactI18next)
  .use(HttpApi) // load from resrouce files
  .use(LanguageDetector) // enable language detector
  .init({
    //resources,
    lng: "en",
    supportedLngs: ["en", "fr"],
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },    
    /*
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    */
    debug: process.env.NODE_ENV === "development",
  });

export default i18next;