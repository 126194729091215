import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form, Input, Alert } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { formInputLayout } from '../const/formConstants';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useRegisterMutation } from '../features/api/authApiSlice';
import { isUserLogin } from '../features/userSlice';


const Register = () => {

  const [errMsg, setErrMsg] = useState([]);
  const [respMsg, setRespMsg] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { from } = location.state || { from: { pathname: '/' } }; // Default to home if no redirect path is provided

  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [register] = useRegisterMutation();
  const isLogin = useSelector(isUserLogin);


  useEffect(() => {
    if (isLogin) {
      navigate("/search");
    }
  }, []);

  const onFinish = (data) => {
    setErrMsg([]);



    const newUser = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      password: data.password
    }

    register(newUser).unwrap()
      .then((data) => {
        setRespMsg(data);
        form.resetFields();

        // TODO: redirect to page ask to check email, 
        navigate("/login");
      })
      .catch((err) => {
        if (err.originalStatus && err.originalStatus == '200') {
          setRespMsg([data]);
          navigate("/login");
        }
        else {
          setErrMsg(err.data);
        }
      });


  }

  return (

    <div>
      <Row justify='center'>
        <Col span={12}>
          <h2>Register</h2>
        </Col>
      </Row>
      <Row justify='center'>
        <Col span={12}>
          <Form {...formInputLayout}
            form={form}
            onFinish={onFinish}>
            <Form.Item name="email" label="Email"
              rules={[
                { required: true },
                { type: "email" }
              ]}>
              <Input />
            </Form.Item>
            <Form.Item name="firstName" label="First Name"
              rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="lastName" label="Last Name"
              rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="password" label="Password"
              rules={[
                {
                  required: true,
                  message: t("formErrorMsg.passwordRequired"),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item name="confirmPassword" label="Confirm Password"
              rules={[
                {
                  required: true,
                  message: t("formErrorMsg.confirmPasswordRequired"),
                },
                {
                  validator: (_, cfmPwd) => {
                    var newPwd = form.getFieldValue("password");
                    if (cfmPwd !== newPwd) {
                      return Promise.reject(
                        t("formErrorMsg.passwordNotMatch")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            {respMsg?.length > 0 &&
              respMsg.map(function (msg, idx) {
                return <Alert message={msg.description} type="success"></Alert>;
              })
            }

            {errMsg?.length > 0 &&
              errMsg.map(function (msg, idx) {
                return <Alert message={msg.description} type="error"></Alert>;
              })
            }

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Register
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default Register;