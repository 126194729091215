import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Typography } from 'antd';

import { useDispatch } from 'react-redux';
import { selectCurrentUser, clearCredential } from '../features/userSlice';
import { initCart } from '../features/cartSlice';


import { useLogoutMutation } from '../features/api/authApiSlice';

const { Title, Text } = Typography;

const LogoutLink = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector(selectCurrentUser);

    const [logout, { isLoading }] = useLogoutMutation();

    const logOffUser = () => {
        logout()
            .unwrap()
            .then((data) => {
                console.log('logout:', data);
                dispatch(clearCredential(user));
                dispatch(initCart({}));
                navigate('/');
            })
            .catch((err) => {
                console.log('logout error:', err);
            });
    };

    return (
        <>
            <Link onClick={logOffUser} >Logout</Link>
        </>
    );

}

export default LogoutLink;