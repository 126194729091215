import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';

import { Card, Row, Col, Typography, Form, Input, Button, Space } from 'antd';
import { EditOutlined, SaveOutlined, CloseCircleOutlined } from '@ant-design/icons';
import {formInputLayout, buttonItemLayout} from '../const/formConstants';

import { addCreditCard, updateCreditCard, removeCreditCard } from '../features/userSlice';

const { Title, Text } = Typography;

const CreditCard = (props) =>  {
    const cardId = props.cardId;
    const [isEdit, setIsEdit] = useState(false);
    const [creditCard, setCreditCard] = useState({});

    const creditCards = useSelector((state) => state.user.creditCards);
    const dispatch = useDispatch();

    const { t } = useTranslation();
    const [form] = Form.useForm();

    useEffect(() => {
        const cc = creditCards.filter((c) => (c.card_id == cardId))[0];     
        setCreditCard(cc);
        console.log("creditCard: ", creditCard);
    },[]);

    const onFinish = (data) => {
        if  (data.cardId)
            dispatch(updateCreditCard(data));
        else
            dispatch(addCreditCard(data));   

        toggleEdit();
    };

    const onDeleteCard = (data) => {
        dispatch(removeCreditCard(data));
    };

    const toggleEdit = (data) =>{                
        setIsEdit(!isEdit);
        const cc = creditCards.filter((c) => (c.card_id == cardId))[0];
        if (!isEdit) {
            form.setFieldsValue({
                cardId: cc.card_id,
                cardName: cc.name,
                cardNumber: cc.number,
                expMonth: cc.expiry_month,
                expYear: cc.expiry_year,
            });
        }        
    }

    return (
        <>
        <Card
            title={props.title}
            >
            {!isEdit &&
                <Form  {...formInputLayout} layout="horizontal" form={form} name="creditcardForm" onFinish={toggleEdit}>
                    <Row justify='center'>
                        <Col span={24}>
                            <Form.Item 
                                label={t('formLabel.nameInput')}
                                name="cardName"
                            >
                                {(creditCards.filter((c) => (c.card_id == cardId))[0]).name}
                            </Form.Item>                    
                            <Form.Item 
                                label={t('formLabel.cardNumberInput')}
                                name="cardNumber"
                            >
                                {(creditCards.filter((c) => (c.card_id == cardId))[0]).number}
                            </Form.Item>                    

                            <Form.Item 
                                label={t('formLabel.expiryMonthInput')}
                                name="expMonth"
                            >
                                {(creditCards.filter((c) => (c.card_id == cardId))[0]).expiry_month}
                            </Form.Item>   

                            <Form.Item 
                                label={t('formLabel.expiryYearInput')}
                                name="expYear"
                            >
                                {(creditCards.filter((c) => (c.card_id == cardId))[0]).expiry_year}
                            </Form.Item>   

                            <Form.Item {...buttonItemLayout}>
                                <Button type="primary" htmlType="submit">
                                <EditOutlined /> {t('formLabel.editButton')}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>                              
                </Form>
            }
            {isEdit &&
            <Form {...formInputLayout} layout="horizontal" form={form} name="creditcardForm" onFinish={onFinish}  >
            <Row justify='center'>
                <Col span={12}>
                    <Form.Item 
                        label={t('formLabel.nameInput')}
                        name="cardName"
                    >
                        <Input/>
                    </Form.Item>                    
                    <Form.Item 
                        label={t('formLabel.cardNumberInput')}
                        name="cardNumber"
                    >
                        <Input/>
                    </Form.Item>                    

                    <Form.Item 
                        label={t('formLabel.expiryMonthInput')}
                        name="expMonth"
                    >
                        <Input/>
                    </Form.Item>   

                    <Form.Item 
                        label={t('formLabel.expiryYearInput')}
                        name="expYear"
                    >
                        <Input/>
                    </Form.Item>   

                    <Form.Item {...buttonItemLayout}>
                        <Space>
                        <Button type="primary" htmlType="submit">
                        <SaveOutlined /> {t('formLabel.saveButton')}
                        </Button>

                        <Button type="defualt" onClick={toggleEdit}>
                        <CloseCircleOutlined /> {t('formLabel.cancelButton')}
                        </Button>
                        </Space>
                    </Form.Item>
                </Col>                
            </Row>
            <Form.Item
                label={t('formLabel.cardIdInput')}
                name="cardId" style={{"display":"none"}}>
                <Input/>
            </Form.Item>

            </Form>
            }
        </Card>
        </>
    );

}

export default CreditCard;