import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Card, Typography, Row, Col, Divider} from 'antd';
import { SearchOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import moment from 'moment';

import UserList  from '../../components/UserList';

// Get Test Data
import { testUsers }  from '../../data/mockData.js';
const fakeDataUrl = 'https://randomuser.me/api/?results=100&inc=name,email,phone,location&noinfo';

const { Title, Text } = Typography;


const Users = () => {
    const [users, setUsers] = useState([]);

    // todo: load data from service
    /*
    const appendData = () => {
        fetch(fakeDataUrl)
          .then((res) => res.json())
          .then((body) => {
            setUsers(users.concat(body.results));   
            console.log(users);
          });
      };
      */

    useEffect(() => {
        // appendData();
        setUsers(testUsers.result);
      }, []);

    return (
        <>
            <Title level={2}>Users</Title>
            <Card>
                <UserList users={testUsers.results}></UserList>
            </Card>
        </>
    );
}

export default Users;