import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    messages: []// array stores messaages with key {key, msgs=[...msgs]}
};

const messageSlice = createSlice({
    name: 'messages',
    initialState: initialState,
    reducers: {
        addMessages: (state, action) => {
            // add array of messages to a messages array with matching key
            console.log("Current messages", state.messages);
            console.log("New messages", action.payload);

            const msgObj = state.messages.filter(m => m.key == action.payload.key);

            if (msgObj) {
                console("add to existing object");
                // key found, append message to found object
                msgObj.msgs = { ...msgObj.msgs, ...action.payload.msgs };

                // remove the found object from messages
                state.messages.filter(m => m.key != action.payload.key);
            }
            else {
                console("add new object");
                // key not found, just add it
                msgObj = action.payload;
            }
            // add object back to message
            state.messages = [...state.messages, msgObj];

            console.log("updated messages", state.messages);

        },
        removeMessages: (state, action) => {
            const key = action.payload;
            state.messages = state.messages.filter(m => m.key != key);
        },
    },
});


export const { addMessages, removeMessages } = messageSlice.actions;

export default messageSlice.reducer;

