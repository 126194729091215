import React from 'react';
import { useSelector } from 'react-redux';
import { ShoppingFilled } from '@ant-design/icons';

const CartCounter = () => {
    // get cart from global state
    const cart = useSelector((state) => state.cart);
    
    return (
        <>
            Cart ({cart.count})
        </>
    )
};

export default CartCounter;