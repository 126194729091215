// Todo: Remove auth service as we switched to JWT.

import Oidc from 'oidc-client';
import {useNavigate} from "react-router-dom";
import authConfig from '../AuthConfig';

class AuthService {
  constructor() {
    this.userManager = new Oidc.UserManager(authConfig);
  }

  login = (redirectPath) => {
    // ToDo: remove after ID Svr 4 integration
    const navigate = useNavigate();
    return navigate(redirectPath);

    // ToDo: Uncomment for ID Svr 4 integration
    // this.userManager.signinRedirect({ state: { redirectPath } }); 
  }; 

  logout = () => {
    this.userManager.signoutRedirect();
  };

  getUser = async () => {
    const user = await this.userManager.getUser();
    return user;
  };

  isAuthenticated = async () => {
    const user = await this.userManager.getUser();
    return !!user;
  };  
}

const authService = new AuthService();
export default authService;
