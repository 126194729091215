import { apiSlice } from "./apiSlice";

export const cartApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCart: builder.mutation({
            query: credentials => ({
                url: 'api/cart/' + credentials.id,
                method: 'GET'
            }),
            providesTags: ['Cart']
        }),
        updateCart: builder.mutation({
            query: cartModel => ({
                url: 'api/cart/' + cartModel.userId,
                method: 'PATCH',
                body: cartModel
            }),
            invalidatesTags: ['Cart']
        }),
        clearCart: builder.mutation({
            query: user => ({
                url: 'api/cart/' + user.id,
                method: 'DELETE'
            }),
            invalidatesTags: ['Cart']
        }),
        getCartItems: builder.mutation({
            query: cartItem => ({
                url: 'api/cart/' + cartItem.cartId + '/cartItem',
                method: 'GET'
            }),
            invalidatesTags: ['Cart']
        }),
        addToCart: builder.mutation({
            query: cartItem => ({
                url: 'api/cart/' + cartItem.cartId + '/cartItem/',
                method: 'POST',
                body: cartItem
            }),
            invalidatesTags: ['Cart']
        }),
        removeFromCart: builder.mutation({
            query: cartItem => ({
                url: 'api/cart/' + cartItem.userId + '/cartItem/' + cartItem.id,
                method: 'DELETE'
            }),
            invalidatesTags: ['Cart']
        }),
    })
})

export const {
    useGetCartMutation,
    useUpdateCartMutation,
    useClearCartMutation,
    useGetCartItemsMutation,
    useAddToCartMutation,
    useRemoveFromCartMutation
} = cartApiSlice 