import React from 'react';
import { useSelector } from 'react-redux';
import { Layout, Menu, Image, Row, Col, Typography, Space } from 'antd';
import { MenuProps } from 'antd';
import { HomeFilled, ShoppingFilled, SearchOutlined, UserOutlined, LogoutOutlined, ToolOutlined, TeamOutlined, BarsOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Logo from '../LogoSq.png'; // Adjust path to your logo file
import CartCounter from './CartCounter';
import LogoutLink from './LogoutLink';
import { selectCurrentUser, selectCurrentToken, isUserLogin } from '../features/userSlice';
import '../styles/App.css';

const { Header } = Layout;
const { Title } = Typography;

const SiteHeader = () => {
  const user = useSelector(selectCurrentUser);
  const isLogin = useSelector(isUserLogin);
  const userState = useSelector((state) => state.user);

  const menuItems = [
    {
      label: <Link to="/">Home</Link>,
      key: 'home',
      icon: <HomeFilled />,
    },
    {
      label: <Link to="/Cart"><CartCounter /></Link>,
      key: 'cart',
      icon: <ShoppingFilled />,
    },
    {
      label: <Link to="/search">Search</Link>,
      key: 'search',
      icon: <SearchOutlined />,
    },
    (!userState.isLogin && {
      label: <Link to="/Login">Login</Link>,
      key: 'login',
      icon: <UserOutlined />,
    }),
    (userState.isLogin && {
      label: user.firstName,
      key: 'user',
      icon: <UserOutlined />,
      children: [
        {
          label: <Link to="/user/profile">My Profile</Link>,
          key: 'profile',
          icon: <UserOutlined />,
        },
        {
          label: <Link to="/user/orders">My Orders</Link>,
          key: 'orders',
          icon: <BarsOutlined />
        },
        {
          label: <LogoutLink />,
          key: 'logout',
          icon: <LogoutOutlined />
        },
      ]
    }),
    (userState.role == "admin" && {
      label: 'Admin',
      key: 'admin',
      icon: <ToolOutlined />,
      children: [
        {
          label: <Link to="/admin/users">Users</Link>,
          key: 'userAdmin',
          icon: <TeamOutlined />
        },
        {
          label: <Link to="/admin/orders">Orders</Link>,
          key: 'orderAdmin',
          icon: <BarsOutlined />
        }
      ]
    }),
    {
      label: <Link to="/about">About Us</Link>,
      key: 'about',
      icon: <InfoCircleOutlined />
    },
  ];


  return (
    <Header>
      <Row>
        <Col span={8}>
          <Space wrap={false} align="start">
            <img src={Logo} className='cpc-logo' />
            <Title level={2} className='cpc-corpName' style={{ color: '#ffffff' }}>
              Corporate Profiles Canada
            </Title>

          </Space>
        </Col>
        <Col span={8} push={8}>
          <Menu theme="dark"
            mode="horizontal"
            defaultSelectedKeys={['home']}
            items={menuItems}>

          </Menu>

        </Col>
      </Row>
    </Header>
  );
};

export default SiteHeader;