import { apiSlice } from "./apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: credentials => ({
                url: 'api/auth/login',
                method: 'POST',
                body: credentials
            }),
            providesTags: ['User']
        }),
        logout: builder.mutation({
            query: () => ({
                url: 'api/auth/logout',
                method: 'POST',
            }),
            invalidatesTags: ['User']
        }),
        changePassword: builder.mutation({
            query: passwordModel => ({
                url: 'api/auth/changePassword',
                method: 'POST',
                body: passwordModel
            })
        }),
        register: builder.mutation({
            query: user => ({
                url: 'api/auth/register',
                method: 'POST',
                body: user
            })
        })
    })
})

export const {
    useLoginMutation,
    useLogoutMutation,
    useChangePasswordMutation,
    useRegisterMutation
} = authApiSlice 