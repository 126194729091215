import React from 'react';
import { useSelector } from 'react-redux';

import { Card, Row, Col, Typography, Form, Input, Select, Button, Space } from 'antd';

const { Title, Text } = Typography;



const ConfirmOrder = () => {
  const order = useSelector(state => state.orders);

  const getOrderNumber = () => {
    return order.lastOrder.orderNumber;
  }

  return (
    <div>
      <h2>Confirm Order: [{getOrderNumber()}]</h2>

      <Title level={4}>Thank you for your order. You will recieve an email once the reports are ready for download</Title>
    </div>
  );
};

export default ConfirmOrder;