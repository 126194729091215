import { apiSlice } from "./apiSlice";
import { selectCurrentCart } from '../cartSlice';
import { selectCurrentUser } from '../authSlice';

export const orderApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        placeOrder: builder.mutation({
            query: order => ({
                url: 'api/order/',
                method: 'POST',
                body: {
                    userId: order.user.id,
                    cartId: order.id,
                    statusCode: 'NEW',
                    firstName: order.user.firstName,
                    lastName: order.user.lastName,
                    phone: order.user.phone,
                    email: order.user.email,
                    addr1: order.user.address,
                    city: order.user.city,
                    provinceCode: order.user.provinceCode,
                    countryCode: order.user.countryCode
                }
            })
        }),
        getOrder: builder.mutation({
            query: order => ({
                url: 'api/order/' + order.id,
                method: 'GET'
            })
        }),
        updateOrder: builder.mutation({
            query: order => ({
                url: 'api/order',
                method: 'PATCH',
                body: order
            })
        }),
        getOrderItems: builder.mutation({
            query: order => ({
                url: 'api/oder/' + order.id + '/orderitem/',
                method: 'get'
            })
        }),
        searchOrders: builder.mutation({
            query: searchParams => ({
                url: 'api/order/search',
                method: 'POST',
                body: searchParams
            })
        })
    })
})

export const {
    usePlaceOrderMutation,
    useGetOrderMutation,
    useUpdateOrderMutation,
    useGetOrderItemsMutation,
    useSearchOrdersMutation
} = orderApiSlice 