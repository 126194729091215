import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setCredentials, clearCredential } from '../../features/userSlice'
import { initCart } from '../cartSlice';

const cpcApiUrl = process.env.REACT_APP_CPC_API_URL;

const baseQuery = fetchBaseQuery({
    // baseUrl: 'https://api-stg.corporateprofilescanada.ca',
    // baseUrl: 'https://localhost:5445',
    baseUrl: cpcApiUrl, // read from environment variables
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const token = getState().user.token
        if (token) {
            headers.set("authorization", `Bearer ${token}`)
        }
        return headers
    }
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)

    if (result?.error?.status === 403 || result?.error?.status === 401) { // attempt to refresh token
        //if (result?.err?.originalStatus === 403) { // attempt to refresh token
        console.log('sending refresh token')
        // send refresh token to get new access token 
        const refreshResult = await baseQuery('/api/auth/refreshToken', api, extraOptions)
        console.log(refreshResult)
        if (refreshResult?.data) {
            // get user from local store
            const user = api.getState().user.user
            // store the new token 
            api.dispatch(setCredentials({ ...refreshResult.data, user }))
            // retry the original query with new access token 
            result = await baseQuery(args, api, extraOptions)
        } else {
            // Refresh failed, force user logout
            api.dispatch(clearCredential());
            api.dispatch(initCart());
        }
    }
    /*
    else if (result?.error?.status === 401) { // 401 error indicates that the refresh token has expired
        api.dispatch(clearCredential())        
    }

    */
    return result;
}

export const apiSlice = createApi({
    reducerPath: 'api', // optional
    // baseQuery: fetchBaseQuery({ baseUrl: 'https://localhost:5445' }),
    // baseQuery: fetchBaseQuery({ baseUrl: 'https://api-stg.corporateprofilescanada.ca' }),
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Corporate'],
    // tagTypes: ['Corporate', 'User'],
    endpoints: builder => ({})
})