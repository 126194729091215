import React, { useEffect, useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';

import { Spin, Input, Button, Card, Table, Space, Typography, Row, Col, Divider, message } from 'antd';
import { SearchOutlined, ShoppingCartOutlined, SecurityScanOutlined } from '@ant-design/icons';

import { useDispatch, useSelector } from 'react-redux';
import { addItemToCart, setCart, selectCurrentCart } from '../features/cartSlice';

// import { useSearchCorporateMutation } from '../features/api/corpSearchApiSlice'
import { useAddToCartMutation, useGetCartMutation } from '../features/api/cartApiSlice';

import { selectCurrentUser } from '../features/userSlice';

import axios from 'axios';
import moment from 'moment';
// import { result } from 'lodash';

const { Title, Text } = Typography;

const SearchResult = ({ searchTerm, province, registerType, businessType, isExactSearch, dateRange, isIncorporated }) => {
  const [results, setResults] = useState([]);
  const [serviceSearchId, setServiceSearchId] = useState('');
  const [searchParams, setSearchParams] = useState({});
  const [searchErrors, setSearchErrors] = useState([]);
  const [searchStatus, setSearchStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [redraw, setRedraw] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();

  const [addToCart] = useAddToCartMutation();
  const [getCart] = useGetCartMutation();

  const [filterText, setFilterText] = useState('');
  const [filteredColumn, setFilteredColumn] = useState('');

  const user = useSelector(selectCurrentUser);
  const cart = useSelector(selectCurrentCart);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // API to perform search 
  // const [searchCorporate, {data, isLoading, isError, isSuccess }] = useSearchCorporateMutation();
  const client = axios.create({
    // baseURL: 'https://api-stg.corporateprofilescanada.ca/api/CompanySearch/Search',
    baseURL: process.env.REACT_APP_CPC_API_URL + '/api/CompanySearch/Search',
  });

  const getSearchParams = () => {
    var params = {};
    params.Jurisdiction = province;
    params.entityName = searchTerm;
    params.exactMatch = isExactSearch;
    params.bsusinessType = isIncorporated ? 1 : 0;

    if (dateRange.length > 0) {
      var startdate = moment(dateRange[0].$d);
      var enddate = moment(dateRange[1].$d);

      params.RegistrationDateStart = startdate.format('YYYY-MM-DD');
      params.RegistrationDateEnd = enddate.format('YYYY-MM-DD');
    }

    setSearchParams(params);

    return params;
  }

  useEffect(() => {
    console.log('dateRange:', dateRange);
    const searchCorprate = async () => {
      setIsLoading(true);
      let response = await
        client.post('',
          getSearchParams()
        ).then(
          (response) => {
            setResults(response.data.entities);
            setServiceSearchId(response.data.serviceSearchId)
            setSearchStatus(response.data.status);
            setSearchErrors(response.data.errors);
            setIsLoading(false);
          }
        )
          .catch((error) => {
            setIsLoading(false);
            setIsError(true);
            console.log(error);
          });
    }
    searchCorprate();
  }, [searchTerm, province, isExactSearch, isIncorporated]);

  const handleAddToCart = (company, checkout) => {
    const idx = cart.items.findIndex((item) => item && item.entityNumber == company.entityNumber);

    // if company not found in cart then add to cart
    if (idx < 0) {
      // todo: append price info
      // company.Price = 16.85;
      company.ServiceSearchId = serviceSearchId;

      // update local store
      dispatch(addItemToCart(company));

      // prepare cart item model for api call
      const addtoCartRequest = {
        CartId: cart.id, // TBD
        EntityName: company.entityName,
        Sku: '1', // default to 1
        EntityNumber: company.entityNumber,
        ServiceSearchId: serviceSearchId,
        Entity: JSON.stringify({ ...company, entityJurisdiction: searchParams.Jurisdiction }),
        Quantity: 1,
        cartItem: {
          CartId: cart.id,
        }
      };

      // call add to cart api
      addToCart(addtoCartRequest).unwrap()
        .then((addToCartResponse) => {
          getCart(user).unwrap()
            .then((getCartResponse) => {
              // sync cart in local store with database
              dispatch(setCart(getCartResponse));

              messageApi.open({
                type: 'success',
                content: 'report added to cart',
                className: 'cpc-message',
              });
            })
        });

      console.log('Added to Cart:', company);

      if (checkout) {
        navigate("/checkout")
      }
      else {
        messageApi.open({
          type: 'success',
          content: 'report added to cart',
          className: 'cpc-message',
        });
      }
    }
  };

  // Table Supporting functions
  // Functions for handling Table Filters
  const handleFilter = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setFilterText(selectedKeys[0]);
    setFilteredColumn(dataIndex);
  };

  const handleFilterReset = (clearFilters) => {
    clearFilters();
    setFilterText('');
  };

  const handleSelectFilter = (value, confirm, dataIndex) => {
    confirm();
    setFilterText(value);
    setFilteredColumn(dataIndex);
  };

  const handleSelectFilterReset = (clearFilters, dataIndex) => {
    clearFilters();
    setFilterText('');
    setFilteredColumn(dataIndex);
  };

  const getColumnSearchProps = (dataIndex, placeholder) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>

        <Input
          placeholder={placeholder}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleFilter(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />

        <Space>
          <Button
            type="primary"
            onClick={() => handleFilter(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleFilterReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
  });

  const getCompanyAddress = (company) => {
    return (company.address.addressLine1 ? company.address.addressLine1 : '') + (company.address.addressLine2 ? company.address.addressLine2 : '');
  };

  const onTableChange = (pagination, filters, sorter) => {
    console.log('params', pagination, filters, sorter);
  };

  const columns = [
    {
      title: 'Entity Name',
      dataIndex: 'entityName',
      key: 'entityName',
      sorter: (a, b) => a.entityName.localeCompare(b.entityName),
      ...getColumnSearchProps('entityName', 'Search Entity Name'),
    },
    {
      title: 'Entity Type',
      dataIndex: 'confirmedEntityType',
      key: 'confirmedEntityType',
      ...getColumnSearchProps('confirmedEntityType', 'Search Confirmed Entity Type'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ...getColumnSearchProps('status', 'Search Status'),
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      sorter: (a, b) => (getCompanyAddress(a).localeCompare(getCompanyAddress(b))),
      ...getColumnSearchProps('address', 'Search Address'),
      render: (text, record) => `${text?.addressLine1 ? text?.addressLine1 : ''} ${text?.addressLine2 ? text?.addressLine2 : ''} ${text?.addressLine3 ? text?.addressLine3 : ''} ${text?.city}`,
    },
    {
      title: 'Entity Number',
      dataIndex: 'entityNumber',
      key: 'entityNumber',
      sorter: (a, b) => a.entityNumber - b.entityNumber,
    },
    {
      title: 'Add to Cart',
      key: 'action',
      render: (text, record) => (
        <Space size="small">
          <Button type="primary" icon={<ShoppingCartOutlined />} onClick={() => handleAddToCart(record)}>
            Add to Cart
          </Button>
          <Button type='default' icon={<SecurityScanOutlined />} onClick={() => handleAddToCart(record, true)} danger >
            Order Now
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Title level={4}>Search for: {searchTerm}</Title>
      {!isLoading &&
        (
          <>
            <Title level={5}>result count: {results.length}</Title>
            <Table
              columns={columns}
              dataSource={[...results]}
              onChange={onTableChange}
              pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}
            />
          </>
        )
      }
      {isLoading &&
        (
          <>
            <Spin tip="Loading..." size="mediums">
              <div style={{ width: '100%', textAlign: 'center' }} />
            </Spin>
          </>
        )
      }
    </>
  );
}

export default SearchResult;