import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Input, Card, Typography, Row, Col, Select, Space } from 'antd';

import OrderList from '../../components/OrderList';

import { selectCurrentUser } from '../../features/userSlice';

import { refreshOrders } from '../../features/orderSlice';
import { useSearchOrdersMutation } from '../../features/api/orderApiSlice';


const { Title, Text } = Typography;
const { Search } = Input;

const Order = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [searchOrders] = useSearchOrdersMutation();

  const orders = useSelector(state => state.orders);
  const user = useSelector(selectCurrentUser);

  useEffect(() => {
    // load oroders from database
    loadOrders();
  }, []);

  const loadOrders = () => {
    searchOrders({ userId: user.id }).unwrap()
      .then((data) => {
        dispatch(refreshOrders(data));
        console.log(data)
      })
  }

  return (
    <>
      <Title level={2}>Orders </Title>
      <Card>
        <Row>
          <Col span={18}>
            <Text strong>{orders.orderList.length} orders in </Text>
            <Select
              defaultValue={3}
              style={{ width: "180px" }}
              options={[
                { value: 3, label: "Last 3 month" },
                { value: 6, label: "Last 6 month" },
                { value: 9, label: "Last 9 month" },
                { value: 12, label: "Last 12 month" }
              ]}>
            </Select>
          </Col>
          <Col span={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {false && (
              <Search
                placeholder="Search all orders..."
                enterButton
              />
            )}
          </Col>
        </Row>
        <OrderList orders={orders.orderList}></OrderList>
      </Card>
    </>
  );
};

export default Order;
