// SearchPage.js
import React, { useState, useEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { Input, Button, Collapse, Select, DatePicker, Checkbox, Card, Table, Space, Typography, Row, Col, Divider, Tag, message } from 'antd';
import { SearchOutlined, ShoppingCartOutlined, SecurityScanOutlined } from '@ant-design/icons';
import moment from 'moment';

// import SearchResults from '../components/SearchResults';
import SearchResult from '../components/SearchResult';

import { useDispatch } from 'react-redux';

// Mock Data: todo - Remove
import { mockCompanySearchResult } from '../data/mockData';
// import { useSearchCorporateMutation } from '../features/api/corpSearchApiSlice'

import { provinceOptions } from '../const/formConstants';

const companiesData = mockCompanySearchResult.Entities;

const { Panel } = Collapse;
const { Option } = Select;
const { Title } = Typography;
const { RangePicker } = DatePicker;


const SearchPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTermsList, setSearchTermsList] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [province, setProvince] = useState('');
  const [registerType, setRegisterType] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [isExactSearch, setIsExactSearch] = useState('');
  const [dateRange, setDateRange] = useState([]);
  const [isIncorporated, setIsIncorporated] = useState(false);
  // const [searchResults, setSearchResults] = useState([]);
  // const [searchResultsList, setSearchResultsList] = useState([]);
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);
  const [token, setToken] = useState(null);

  // Table Filter
  const [filterText, setFilterText] = useState('');
  const [filteredColumn, setFilteredColumn] = useState('');

  const [messageApi, contextHolder] = message.useMessage();

  // React Router hook to get the current location
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // API to perform search
  // const [searchCorporate, {data, isLoading, isError, isSuccess }] = useSearchCorporateMutation();

  // retrieve search param from query string
  useEffect(() => {
    // Parse query string parameters
    const params = new URLSearchParams(location.search);
    const searchTermFromQuery = params.get('c');

    setDefaultSearchParam();

    // Set the search term from the query string, if available
    if (searchTermFromQuery) {
      const searchList = searchTermFromQuery.split(';')
      setTagsList(searchList);
      setSearchTermsList(searchList);
    }
  }, []); // Empty dependency array ensures the effect runs only once, similar to componentDidMount

  const handleSearch = () => {
    // Clear Current Search
    setSearchTermsList([]);

    // Bring text from search box 
    var newTagsList = tagsList;
    if (searchTerm != '') {
      newTagsList = [...tagsList, ...searchTerm.split(';')];

      setTagsList(newTagsList);
      setSearchTerm('');
    }

    // Trigger Search
    setSearchTermsList(tagsList);

  };

  // Show/Hide Advance Search Panel
  const handleAdvancedSearch = () => {
    setIsAdvancedSearchOpen(!isAdvancedSearchOpen);
  };

  // Set Advance Search fields
  const handleProvinceChange = (value) => {
    setSearchTermsList([]);
    setProvince(value);
  };

  const handleDateRangeChange = (dates) => {
    setSearchTermsList([]);
    setDateRange(dates);
  };

  const handleIsIncorporatedChange = (e) => {
    setSearchTermsList([]);
    setIsIncorporated(e.target.checked);
  };

  const handleIsExactSearchChange = (e) => {
    setSearchTermsList([]);
    setIsExactSearch(e.target.checked);
  };
  // Clear Search Fields
  const handleClearSearch = () => {
    setSearchTerm('');
    setDefaultSearchParam();

    // Clear tags and search results
    setTagsList([]);
    setSearchTermsList([]);
  };

  const setDefaultSearchParam = () => {
    setProvince('ON');
    setDateRange([]);
    setIsIncorporated(false);
    setIsExactSearch(false);
    setRegisterType('');
    setBusinessType('');
  }

  // Tags managements
  const handleClose = (removedTag) => {
    const newTags = tagsList.filter(tag => tag !== removedTag);
    console.log(newTags);
    setTagsList(newTags);
  };

  const handleSearchTermConfirm = () => {
    if (searchTerm && tagsList.indexOf(searchTerm) === -1) {
      setTagsList([searchTerm.trim(), ...tagsList]);
    }
    setSearchTerm('');
  };

  const forMap = (tag) => {
    const tagElem = (
      <Tag
        key={tag}
        closable
        onClose={e => {
          e.preventDefault();
          handleClose(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span key={tag} style={{ display: 'inline-block' }}>
        {tagElem}
      </span>
    );
  };

  // Commponent layout
  return (
    <>
      <Title level={2}>Search Companies</Title>
      <Card
      >
        {contextHolder}
        <Input
          placeholder="Enter company name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onBlur={handleSearchTermConfirm}
          onPressEnter={handleSearch}
        />

        <div style={{ marginTop: 16 }}>
          {tagsList.map(forMap)}
        </div>

        <Collapse accordion activeKey={isAdvancedSearchOpen ? '1' : null} style={{ border: 'none', background: 'transparent' }}>
          <Panel key="1" showArrow={false}>

            <Row align={'middle'}>
              <Col span={2}>
                Province
              </Col>
              <Col span={10}>
                <Select
                  options={provinceOptions}
                  placeholder="Select Province"
                  style={{ width: '90%', marginBottom: '16px' }}
                  onChange={handleProvinceChange}
                  value={province}
                >
                </Select>
              </Col>
              <Col span={6}>
                <Checkbox
                  onChange={handleIsIncorporatedChange}
                  checked={isIncorporated}
                >
                  Is Incorporated
                </Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox
                  onChange={handleIsExactSearchChange}
                  checked={isExactSearch}
                >
                  Match Exact Name
                </Checkbox>
              </Col>
            </Row>

            <Row align={'middle'}>
              <Col span={2}>
                Registered Between
              </Col>
              <Col span={22}>
                <RangePicker
                  style={{ width: '100%', marginBottom: '16px' }}
                  placeholder={['Start Date', 'End Date']}
                  onChange={handleDateRangeChange}
                  value={dateRange}
                />
              </Col>

            </Row>
          </Panel>
        </Collapse>

        <Row>
          <Col span={6} push={18}>
            <Space>
              <Button type="primary" onClick={handleSearch}>
                Search
              </Button>

              <Button type="default" onClick={handleClearSearch}>
                Clear
              </Button>

              <Button type="link" onClick={handleAdvancedSearch}>
                {isAdvancedSearchOpen ? 'Hide Advanced Search' : 'Show Advanced Search'}
              </Button>

            </Space>
          </Col>
        </Row>

        {searchTermsList.length > 0 && (
          <>
            <Card style={{ overflow: 'auto', marginTop: '16px', marginBottom: '16px', padding: '10px' }}>
              {
                searchTermsList.map(corporateName => (
                  <>
                    <SearchResult
                      key={corporateName}
                      searchTerm={corporateName}
                      province={province}
                      isExactSearch={isExactSearch}
                      IsIncorporated={isIncorporated}
                      dateRange={dateRange}
                    />
                  </>
                ))}
            </Card>
            <Divider></Divider>
            <Row>
              <Col span={2} push={20}>
                <Space>
                  <Button type="primary">
                    <Link to='/Cart'>Go to Cart</Link>
                  </Button>

                  <Button type="default" onClick={handleClearSearch}>
                    Clear
                  </Button>

                </Space>
              </Col>
            </Row>
          </>
        )}



      </Card>
    </>
  );
};

export default SearchPage;
