import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Table, Typography, Row, Col, Divider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { currencyFormatter } from '../const/formConstants';

import { useRemoveFromCartMutation, useGetCartMutation } from '../features/api/cartApiSlice';
import { removeItemFromCart, setCart } from '../features/cartSlice';
import { selectCurrentUser } from '../features/userSlice';

import { ShoppingCartOutlined, DeleteOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart = useSelector(state => state.cart);

  const [removeFromCart] = useRemoveFromCartMutation();
  const [getCart] = useGetCartMutation();

  const user = useSelector(selectCurrentUser);

  useEffect(() => {
    getCart(user).unwrap()
      .then((getCartResponse) => {
        // update cart in global state
        dispatch(setCart(getCartResponse));
      })
  }, []);

  const handleRemoveFromCart = (company) => {

    removeFromCart({ ...company, userId: user.id }).unwrap()
      .then(() =>
        getCart(user).unwrap()
          .then((getCartResponse) => {
            // remove from UI
            dispatch(removeItemFromCart(company));

            // update cart in global state
            dispatch(setCart(getCartResponse));
          })
      )
      ;
  };

  const handleCheckOut = () => {
    navigate('/checkout');
  }

  const columns = [
    {
      title: 'Entity Name',
      dataIndex: 'entityName',
      key: 'entityName',
    },
    {
      title: 'Entity Number',
      dataIndex: 'entityNumber',
      key: 'entityNumber',
    },
    {
      title: 'Entity Type',
      dataIndex: 'confirmedEntityType',
      key: 'confirmedEntityType',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      render: (text, record) => `${text?.addressLine1 ? text?.addressLine1 : ''} ${text?.addressLine2 ? text?.addressLine2 : ''} ${text?.addressLine3 ? text?.addressLine3 : ''} ${text?.city}`,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (price, record) => (
        <Text>$ {price}</Text>
      ),
    },
    {
      title: 'Delete',
      key: 'action',
      render: (text, record) => (
        <Button type="default" icon={<DeleteOutlined />} onClick={() => handleRemoveFromCart(record)}>
          Delete
        </Button>
      ),
    },
  ];

  return (
    <>
      <Title level={2}>My Cart</Title>
      <Row gutter={[12, 12]}>
        <Col span={cart.count > 0 ? 20 : 24}>
          <Card>
            <Table
              columns={columns}
              dataSource={cart.items}
              pagination={false}
            />

          </Card>
        </Col>
        {(cart.count > 0) &&
          <Col span={4}>
            <Card title="Check Out"
            >
              <Text>Sub Total ({cart.count} item{cart.count > 1 ? "s" : ""}): {currencyFormatter.format(cart.subTotal)}</Text>
              <Divider></Divider>
              <Button
                type="primary"
                onClick={handleCheckOut}
              >Proceed to Check out</Button>
            </Card>
          </Col>
        }
      </Row>
    </>
  );
};

export default Cart;
