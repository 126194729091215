// CreditCardList.js
import React from 'react';
import moment from 'moment';
import axios from "axios";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, List, Row, Col, Typography, Button } from 'antd';
import { DownloadOutlined, SecurityScanOutlined } from '@ant-design/icons';
import { currencyFormatter } from '../const/formConstants';

import { selectCurrentUser, selectCurrentToken } from '../features/userSlice';

import CompanyInfo from './CompanyInfo';

import '../styles/App.css';

const { Text, Title } = Typography;

const OrderList = ({ orders }) => {
  const token = useSelector(selectCurrentToken);

  const downloadReport = (fileId, entityName) => {
    const cpcApiUrl = process.env.REACT_APP_CPC_API_URL;
    const pdfUrl = cpcApiUrl + "/api/report/" + fileId;

    try {

      axios
        ({
          method: "GET",
          url: pdfUrl,
          responseType: 'blob',
          headers: {
            'authorization': 'Bearer  ' + token,
            'Content-Type': 'application/pdf'
          }
        }).then((res) => {
          // Step 2: Check if the response status is 200 (OK) and data is present
          console.log(res.data);

          const contentDisposition = res.headers['content-disposition'];
          const fileName = contentDisposition
            ? contentDisposition.split('filename=')[1].split(';')[0].replace(/"/g, '')
            : entityName + '.pdf';

          // Step 5: Create a download link for the Blob
          const url = URL.createObjectURL(res.data);
          const a = document.createElement("a");
          a.href = url;
          a.setAttribute('download', fileName);

          // Step 6: Set the download attribute and trigger the download
          document.body.appendChild(a);
          a.click();

          // Step 7: Clean up the temporary URL
          document.body.removeChild(a);
          URL.revokeObjectURL(url);


        }).catch((error) => {
          // Step 2 (Error): Handle any other errors that may occur during processing
          alert("Something went wrong 2");

        })
    }
    catch (error) {
      console.log(error);
      alert("Something went wrong 3");
    }
  };

  const renderOrder = (order) => (
    <List.Item>
      <Row className='cpc-order-header'>
        <Col span={16}>
          <Row align={'start'}>
            <Col>
              <Text strong>ORDER#</Text> &nbsp;
              <Text>{order.orderNumber}</Text>
            </Col>
          </Row>
          <Row align={'start'}>
            <Col>
              <Link to="/user/order">view order details</Link> | <Link>Invoice</Link>
            </Col>
          </Row>
        </Col>
        <Col span={4}>
          <Text strong>ORDER PLACED</Text><br />
          <Text>{moment(order.createdDatetime).format('LLLL')}</Text>
        </Col>
        <Col span={4}>
          <Text strong>TOTAL</Text><br />
          <Text>{currencyFormatter.format(order.orderTotal)}</Text>
        </Col>
      </Row>
      <Row className='cpc-order-content' justify="center">
        <Col span={16}>
          <List
            itemLayout='vertical'
            dataSource={order.orderItems}
            renderItem={renderOrderItem}
          >

          </List>
        </Col>
      </Row>
    </List.Item>
  );

  const renderOrderItem = (orderItem) => (
    <List.Item>
      <Card
        title={orderItem.EntityName}
        extra={orderItem.BusinessNumber}
      >
        <Row>
          <Col span={20}>
            <CompanyInfo company={JSON.parse(orderItem.entity)}></CompanyInfo>
          </Col>
          <Col span={4}>
            <Row align={'end'}>
              <Col >
                {orderItem.orderItemStatusCode == 'COMPD' && (
                  <>
                    <Button style={{ margin: "5px" }} type='primary' block onClick={() => downloadReport(orderItem.id, orderItem.BusinessNumber)}><DownloadOutlined />Download</Button> <br />
                  </>)}
                {orderItem.orderItemStatusCode != 'COMPD' && (
                  <>
                    <Button style={{ margin: "5px" }} type='dashed' block >{orderItem.orderItemStatusTitle}</Button> <br />
                  </>
                )}
                {false && (
                  <>
                    < Button style={{ margin: "5px" }} type='default' danger block><SecurityScanOutlined />Order Again</Button> <br />
                  </>)}
              </Col>
            </Row>
          </Col>
        </Row>
      </Card >
    </List.Item >
  );

  return (
    <List
      itemLayout='vertical'
      dataSource={orders}
      renderItem={renderOrder}
      pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}
    />
  );
};

export default OrderList;