// CreditCardList.js
import React from 'react';
import { Card, List, Radio,  Typography } from 'antd';

const { Text } = Typography;

const CreditCardList = ({ creditCards, selectedCard, onCardSelect, allowSelect }) => {
  const renderItem = (card) => (
    <List.Item key={card.id}>
      {allowSelect &&
      <Radio
        value={card.id}
        checked={selectedCard === card.id}
        onChange={() => onCardSelect(card.id)}
      >
        <Text>
          <b>{card.name}</b> with number <b>{card.number}</b> expire on <b>{card.expiry_month}/${card.expiry_year}</b>
        </Text>
      </Radio>
      }
      {!allowSelect &&
        <Text>
          <b>{card.name}</b> with number <b>{card.number}</b> expire on <b>{card.expiry_month}/${card.expiry_year}</b>
        </Text>
      }
    </List.Item>
  );

  return (
    <Card title="2 Payment Method">
    <List
      dataSource={creditCards}
      renderItem={renderItem}
      bordered
      itemLayout="vertical"
    />
    </Card>
  );
};

export default CreditCardList;