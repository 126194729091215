import React from 'react';
import { Input } from 'antd';

const { Search } = Input;

function SearchBox({ onSearch }) {
  const handleSearch = (value) => {
    onSearch(value);
  };

  return (
    <Search
      placeholder="Search..."
      onSearch={handleSearch}
      enterButton
    />
  );
}

export default SearchBox;
