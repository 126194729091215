import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  lastOrder: {},
  orderList: []
};

const cartSlice = createSlice({
  name: 'orders',
  initialState: initialState,
  reducers: {
    placeOrder: (state, action) => {
      // To Do: REMOVE!!
      console.log('placeOrder:', action.payload);

      // todo: place order to service
      var orderNumber = crypto.randomUUID();
      var order = action.payload;
      order.id = orderNumber;
      order.createDatetime = new Date().toLocaleString();
      state.orderList.push(order);

      console.log('orders  state:', state.orderList);

    },
    setLastOrder: (state, action) => {
      state.lastOrder = action.payload;
    },
    refreshOrders: (state, action) => {
      state.orderList = action.payload;
    }

  },
});

export const { placeOrder, setLastOrder, refreshOrders } = cartSlice.actions;

export default cartSlice.reducer;