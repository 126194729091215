import { apiSlice } from "./apiSlice";

export const cpcApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUser: builder.mutation({
            query: user => ({
                url: 'api/user/' + user.id,
                method: 'GET',
                body: user
            })
        }),
        updateUser: builder.mutation({
            query: user => ({
                url: 'api/user',
                method: 'PATCH',
                body: user
            })
        }),
        deleteUser: builder.mutation({
            query: user => ({
                url: 'api/user',
                method: 'DELETE',
                body: user
            })
        })
    })
})

export const {
    useGetUserMutation, useUpdateUserMutation, useDeleteUserMutation,
} = cpcApiSlice 