import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Input, Button, Collapse, Select, DatePicker, Checkbox, Card, Table, Space, Typography, Row, Col, Divider, Tag } from 'antd';
import { SearchOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import moment from 'moment';

import { useDispatch } from 'react-redux';

const { Title, Text } = Typography;

const Orders = () => {
    return (
        <>
            <Title level={2}>Orders</Title>
        </>
    );
}

export default Orders;