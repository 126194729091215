// CartItemsList.js
import React from 'react';
import { useSelector } from 'react-redux';
import { Card, List, Typography, Row, Col } from 'antd';
import { BankOutlined, EnvironmentOutlined } from '@ant-design/icons';
import CompanyInfo from './CompanyInfo';
import Meta from 'antd/es/card/Meta';

import {currencyFormatter} from '../const/formConstants';

const { Text, Paragraph,  Title } = Typography;

const CartItemsList = ({cartItems}) => {
    const cart = useSelector(state => state.cart);

    const renderItem = (cartItem) => (
        <List.Item key={cartItem.EntityNumber}> 
            <CompanyInfo company={cartItem} showPrice></CompanyInfo>                          
        </List.Item>
      );

    return (
        <Card title="3 Review items">
            <List
                itemLayout='vertical'
                dataSource = {cartItems}
                renderItem = {renderItem}
                footer = {
                    <Row>
                        <Col span={2} offset={19}>
                            <Text strong type="danger">Order Total</Text>
                        </Col>
                        <Col span={3}>
                            <Text strong type="danger">{currencyFormatter.format(cart.orderTotal)}</Text>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                }
                bordered

                >                
            </List>

        </Card>

    );
};

export default CartItemsList;