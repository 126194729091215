import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";

import { Card, Row, Col, Typography, Form, Input, Button, Alert } from "antd";
import { RedoOutlined } from "@ant-design/icons";

import { selectCurrentUser } from "../features/userSlice";
import { useChangePasswordMutation } from "../features/api/authApiSlice";
import { addMessages, removeMessages } from "../features/messageSlice";

import { formInputLayout, buttonItemLayout } from "../const/formConstants";

const { Title, Text } = Typography;

const ChangePassword = (props) => {
    const componentKey = 'changePasswordComponent';

    const [isEdit, setIsEdit] = useState(false);
    const [errMsg, setErrMsg] = useState([]);
    const [respMsg, setRespMsg] = useState([]);
    const [testMsg, setTestMsg] = useState('');

    const [changePassword, { data, error, isLoading, refetch }] = useChangePasswordMutation();

    const user = useSelector(selectCurrentUser);
    const comMsg = useSelector(state => state.messages);
    const dispatch = useDispatch();

    const { t } = useTranslation();
    const [form] = Form.useForm();

    const navigate = useNavigate();



    const onFinish = async (data) => {
        // clear error message
        setErrMsg([]);
        setTestMsg('');

        // call api
        try {
            dispatch(removeMessages(componentKey));

            const response = await changePassword({
                UserId: user.id,
                CurrentPassword: data.currentPassword,
                NewPassword: data.newPassword,
            });

            console.log('change password successs:', response);
            if (response.error?.status === 400) {
                var messages = response.error.data.map((msg) => msg.description);
                setErrMsg([...messages]);
                dispatch(addMessages({ key: componentKey, msgs: messages }));

                // TESTING CODE
                setTestMsg(response.error.data[0].description);

                console.log("Errors from server", messages);
                console.log("Message in state", comMsg);

            }
            if (response.originalStatus == 200) {
                setRespMsg([t('changePasswordForm.successMsg')]);

                // TESTING CODE
                setTestMsg(response.error.data[0].description);

                form.resetFields();
            }

        } catch (err) {
            // todo: issue handling of server errors
            if (err.status === 400) {
                var messages = err.error.data.map((msg) => msg.description);
                setErrMsg([...messages]);

                console.log("Errors from server", messages);
            }
            if (err.originalStatus == 200) {
                console.log("change password successs:", err);
                form.resetFields();
            }
            console.log("change password error:", err);
        }
    };

    return (
        <>
            <Card title={props.title}>
                <Form
                    {...formInputLayout}
                    form={form}
                    onFinish={onFinish}
                >
                    <Row justify="center">
                        <Col span={12}>
                            <Form.Item
                                label={t("formLabel.currentPasswordInput")}
                                name="currentPassword"
                                rules={[
                                    {
                                        required: true,
                                        message: t("formErrorMsg.passwordRequired"),
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>

                            <Form.Item
                                label={t("formLabel.newPasswordInput")}
                                name="newPassword"
                                rules={[
                                    {
                                        required: true,
                                        message: t("formErrorMsg.passwordRequired"),
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>

                            <Form.Item
                                label={t("formLabel.confirmPasswordInput")}
                                name="confirmPassword"
                                rules={[
                                    {
                                        required: true,
                                        message: t("formErrorMsg.confirmPasswordRequired"),
                                    },
                                    {
                                        validator: (_, cfmPwd) => {
                                            var newPwd = form.getFieldValue("newPassword");
                                            if (cfmPwd !== newPwd) {
                                                return Promise.reject(
                                                    t("formErrorMsg.passwordNotMatch")
                                                );
                                            }
                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>

                            {respMsg.length > 0 &&
                                respMsg.map(function (msg, idx) {
                                    return <Alert message={msg.description} type="success"></Alert>;
                                })
                            }

                            {errMsg.length > 0 &&
                                errMsg.map(function (msg, idx) {
                                    return <Alert message={msg.description} type="error"></Alert>;
                                })
                            }

                            <Form.Item {...buttonItemLayout}>
                                <Button type="primary" htmlType="submit">
                                    <RedoOutlined />
                                    {t("formLabel.resetPasswordButton")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </>
    );
};

export default ChangePassword;
