import React, { useEffect, useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Input, Button, DatePicker, Table, Space, Typography, Popconfirm, message } from 'antd';

import { GetRef, TableColumnsType, TableColumnType } from 'antd';

import { SearchOutlined, BarsOutlined } from '@ant-design/icons';

import '../styles/App.css';

// Mock Data: todo - Remove
import { mockCompanySearchResult } from '../data/mockData';


const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

const UserList = (props) => {
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    
    // Load users from props
    const users = props.users;

    // Table Filter
    const [filterText, setFilterText] = useState('');
    const [filteredColumn, setFilteredColumn] = useState('');

    // Pop confirm state
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);   
    const [passwordResetUser, setPasswordResetUser] = useState({});
 

    const handleViewOrder = (user) => {        
        console.log('View User Order:', user);
        navigate({
            // pathname: '/admin/userOrders',
            pathname: '/user/orders',
            search: `?u=${user.name.first}`
        });
    };


    const showPopconfirm = (user) => {
        console.log('Reset User Passoword:', user);
        setPasswordResetUser(user);
        setOpen(true);
    }

    const handleOk = () => {
        setConfirmLoading(true);

        //todo:  send password reset action to PasswordResetUser
    
        setTimeout(() => {
          setOpen(false);
          setConfirmLoading(false);
        }, 2000);

        messageApi.open({
            type: 'success',
            content: 'Reset email sent',
            className: 'cpc-message',
          });

      };
    
      const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpen(false);
      };    

      const handleImpersonate = (user) => {
        setConfirmLoading(true);

        //todo:  impersonate user
    
        setTimeout(() => {
          setOpen(false);
          setConfirmLoading(false);
        }, 2000);

        messageApi.open({
            type: 'success',
            content: 'Impersonate ' + user.name,
            className: 'cpc-message',
          });
      }

    // Functions for handling Table Filters
    const handleFilter = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setFilterText(selectedKeys[0]);
        setFilteredColumn(dataIndex);
    };

    const handleFilterReset = (clearFilters) => {
        clearFilters();
        setFilterText('');
    };

    const handleSelectFilter = (value, confirm, dataIndex) => {
        confirm();
        setFilterText(value);
        setFilteredColumn(dataIndex);
    };

    const handleSelectFilterReset = (clearFilters, dataIndex) => {
        clearFilters();
        setFilterText('');
        setFilteredColumn(dataIndex);
    };  

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>

            <Input
            placeholder={placeholder}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleFilter(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            />

            <Space>
            <Button
                type="primary"
                onClick={() => handleFilter(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
            >
                Search
            </Button>
            <Button onClick={() => handleFilterReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
            </Button>
            </Space>
        </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
        record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    });

    const getAddress = (location) => {
        return (location.street.number ? location.street.number : '') + (location.street.name ? location.street.name : '') + (location.city ? location.city : '');
      };      

    const onTableChange = (pagination, filters, sorter) => {
         console.log('params', pagination, filters, sorter);
    };      

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',      
            key: 'name',      
            render: (name) => `${name.first} ${name.last}`,
            sorter: (a, b) => a.name.first.localeCompare(b.name.first),
            ...getColumnSearchProps('FirstName', 'Search First Name'),
        },
        {
            title: 'Email',
            dataIndex: 'email',      
            key: 'email',            
            sorter: (a, b) => a.email.localeCompare(b.email),
            ...getColumnSearchProps('Email', 'Search Email'),
        },        
        {
            title: 'Phone',
            dataIndex: 'phone',      
            key: 'phone',            
            sorter: (a, b) => a.phone.localeCompare(b.phone),
            ...getColumnSearchProps('Phone', 'Search Phone'),
        },        
        {
            title: 'Address',
            dataIndex: 'location',
            key: 'location',
            sorter: (a, b) => (getAddress(a).localeCompare(getAddress(b))),
            ...getColumnSearchProps('Address', 'Search Address'),
            render: (text, record) => getAddress(text),
          },       
          {
            title: 'Actions',
            key: 'action',
            render: (text, record) => (
              <Space>
                <Button type="primary" onClick={() => handleViewOrder(record)}>
                    Orders
                </Button>


                <Popconfirm
                    title="Confirm Reset Password"
                    description=""                    
                    onConfirm={handleOk}
                    okButtonProps={{ loading: confirmLoading }}
                    onCancel={handleCancel}
                    >
                    <Button type="primary" danger onClick={() => showPopconfirm(record)}>
                        Reset Password
                    </Button>                        
                </Popconfirm>  

                <Popconfirm
                    title="Confirm Impersonate"
                    description=""                    
                    onConfirm={handleImpersonate(record)}
                    okButtonProps={{ loading: confirmLoading }}
                    onCancel={handleCancel}
                    >
                    <Button type="primary" danger onClick={() => showPopconfirm(record)}>
                        Impersonate
                    </Button>                        
                </Popconfirm>                              
              </Space>
            ),
          },          
    ]

    return (
        <>            
            {contextHolder}
            <Table
              columns={columns}
              dataSource={users}
              onChange={onTableChange}
              pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}
            >
            </Table>
        </>
    );

}

export default UserList;