import React, {useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, Typography, Row, Col, Space, Button} from 'antd';



import OrderSummary from '../../components/OrderSummary';
import CreditCardList from '../../components/CreditCardList';
import CartItemsList from '../../components/CartItemsList';
import Address from '../../components/Address';

const { Title, Text } = Typography;

const UserOrderDetals = () => {
    const { t } = useTranslation();

    const orders = useSelector(state => state.orders);    
    
    // todo: Get order from service
    const order = orders.orderList[6];

    return (
        <>            
            <Title level={2}>Order# {order.id}</Title>
            <Row>
                <Col span={16}>
                    <Space size="middle" direction="vertical" style={{ display: 'flex' }}>
                    <Address title="1 Billing Address" address={order.user.address} readonly/>         
                    <CreditCardList creditCards={[order.creditCard]} />
                    <CartItemsList cartItems={order.items}/>
                    </Space>
                </Col>
                <Col span={8}>
                    <Card>
                        <OrderSummary order={order}/>
                    </Card>
                </Col>
            </Row>

        </>
    );
};

export default UserOrderDetals;