import React from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';
import SearchBox from '../components/CompanySearch';

const Home = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleSearch = (value) => {
    // redirect user to search result
    const params = {c: value}
    navigate({
       pathname: '/search',
       search: `?${createSearchParams(params)}`
      });
  };  

  return (
    <div>
      <h2>Welcome to the Home Page</h2>     
      <SearchBox onSearch={handleSearch}/> 
    </div>
  );
};

export default Home;
