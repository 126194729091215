import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  cartItems: [],
  id: '',
  subTotal: 0,
  tax: 0,
  orderTotal: 0,
  count: 0,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState: initialState,
  reducers: {
    setCart: (state, action) => {
      // load cart from api
      console.log('loadCart:', action.payload);
      state.id = action.payload.id;
      state.subTotal = action.payload.subTotal;
      state.tax = action.payload.tax;
      state.orderTotal = action.payload.orderTotal;
      state.cartItems = action.payload.cartItems;
      state.count = action.payload.count;

      state.items = [];
      action.payload.cartItems.forEach((cartItem) => {
        state.items.push({
          id: cartItem.id,
          serviceSearchId: cartItem.serviceSearchId,
          quantity: 1,
          sku: cartItem.sku,
          price: cartItem.price,
          ...JSON.parse(cartItem.entity)
        });
      })

    },
    addItemToCart: (state, action) => {
      console.log('addToCart:', action.payload);

      const entity = action.payload;
      const idx = state.items.findIndex((item) => item && item.entityNumber == entity.entityNumber);
      console.log('idx:', idx);
      if (idx < 0) {
        console.log('add to cart:');
        // Add to cart item list
        state.items.push({ ...action.payload, quantity: 1, sku: 1 });

        state.subTotal += entity.Price;
        state.tax = (state.items.length * 0.07);
        state.orderTotal = state.subTotal + state.tax;
        state.count += 1;
      }
    },
    removeItemFromCart: (state, action) => {
      console.log('removeItemFromCart:', action.payload);
      const entity = action.payload;
      const idx = state.items.findIndex((item) => item && item.entityNumber == entity.entityNumber);
      console.log('idx:', idx);
      if (idx >= 0) {
        console.log('remove from cart:');
        state.items.splice(idx, 1);
        state.subTotal -= entity.Price;
        state.tax = state.subTotal * 0.07;
        state.orderTotal = state.subTotal + state.tax;
        state.count -= 1;
      }
    },
    initCart: (state, action) => {
      console.log('placeOrder:', action.payload);

      // Set to initial state
      state.id = '';
      state.items = [];
      state.cartItems = [];
      state.subTotal = 0;
      state.tax = 0;
      state.orderTotal = 0;
      state.count = 0;

      console.log('cart  state:', state);
    }
  },
});

export const { setCart, addItemToCart, removeItemFromCart, initCart } = cartSlice.actions;
export const selectCurrentCart = (state) => state.cart;

export default cartSlice.reducer;

