// ToDo: move configuration to environment variables
const authConfig = {
    authority: 'https://your-identity-server-url',
    client_id: 'your-client-id',
    redirect_uri: 'http://localhost:3000/signin-oidc',
    response_type: 'code',
    scope: 'openid profile email',
    post_logout_redirect_uri: 'http://localhost:3000/signout-callback-oidc',
  };
  
  export default authConfig;
  