import React, { useState } from 'react';
import { Row, Col, Form, Input, Button, Typography, Alert, Anchor } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formInputLayout } from '../const/formConstants';
import authService from '../services/AuthService';

const { Title, Paragraph } = Typography;

const ForgotPasswordForm  = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const { register, handleSubmit, errors } = useForm();
    const [step, setStep] = useState(1);  

    const onSubmitEmail = (data) => {
        // Send email for verification, update UI accordingly
        // For simplicity, just move to the next step
        // Toto: Add server logic
        setStep(2);
      };    

    return (
        <>
        <Row justify='center'>
            <Col span={12}>
            <Title level={2}>{t('forgotPasswordForm.title')}</Title>
            </Col>
        </Row>   
        {step === 1 && (            
            <>
            <Form onFinish={handleSubmit(onSubmitEmail)}  {...formInputLayout}>
                <Row justify='center'>
                    <Col span={12}>
                    <Form.Item 
                        label={t('formLabel.emailInput')}
                        name="email"
                        rules={[
                        { required: true, message: t('formErrorMsg.emailRequired') },
                        ]}
                    >
                        <Input type="text" />
                    </Form.Item>
                    </Col>
                </Row>
                <Row justify='center'>
                    <Col span={1} offset={11}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                        {t('formLabel.nextButton')}
                        </Button>
                    </Form.Item>            
                    </Col>
                </Row>
            </Form>            
            </>
        )}

        {step === 2 && (
            <>
            <Row justify='center'>
                <Col span={12}>
                    <Typography>
                        <Paragraph>
                            {t('forgotPasswordForm.instruction')}
                        </Paragraph>
                    </Typography>
                </Col>
            </Row>
            </>
        )}
             
        </>
    );
  
};

export default ForgotPasswordForm ;


