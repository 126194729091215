import { createSlice } from '@reduxjs/toolkit';

// todo: Remove
import { userAddress, testCreditCard, testUser } from '../data/mockData';

const initialState = {
  user: {},
  // todo: remove fieldss
  // ------------------------------------
  address: {},
  creditCards: [{ name: "", number: "", expiry_month: "", expiry_year: "", card_type: "" }],
  // ------------------------------------
  isLogin: false,
  rememberMe: false,
  token: "",
  role: "user",
};


const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setCredentials: (state, action) => {
      console.log('login:', action.payload);
      const user = action.payload.user;
      state.user = action.payload.user;

      // to simplify the fields
      state.id = user.id;
      state.email = user.email;

      // login use and populate user profile information        
      state.address = userAddress; // todo: load from service
      state.creditCards = [testCreditCard]; // todo: load from service

      // Set access tokens
      state.token = action.payload.token;

      state.rememberMe = action.payload.rememberMe;

      // Set Login State
      state.isLogin = true;
    },
    clearCredential: (state, action) => {
      console.log('clearCredential:', action.payload);
      const entity = action.payload;

      state.user = {};
      state.token = "";

      // Set Login State
      state.isLogin = false;
    },
    updateProfile: (state, action) => {
      console.log('update profile:', action.payload);
      const entity = action.payload;
      state.user.firstName = entity.firstName;
      state.user.lastName = entity.lastName;
      state.user.phone = entity.phone;
      state.user.email = entity.email;
    },
    updateAddress: (state, action) => {
      console.log('update address:', action.payload);
      const entity = action.payload;

      state.user.address = entity.address;
      state.user.province = entity.province;
      state.user.provinceCode = entity.provinceCode;
      state.user.country = entity.country;
      state.user.countryCode = entity.countryCode;
      state.user.city = entity.city;
      state.user.postalZip = entity.postalZip;

      // toDo: to remove
      state.address = entity;
    },
    addCreditCard: (state, action) => {
      console.log('add credit card:', action.payload);
      const entity = action.payload;

      state.creditCards.push(entity);
    },
    updateCreditCard: (state, action) => {
      console.log('update credit card:', action.payload);
      const entity = action.payload;
    },
    removeCreditCard: (state, action) => {
      console.log('remove credit card:', action.payload);
      const entity = action.payload;
      const cardId = entity.card_id;

      var cc = [];
      state.creditCards.forEach(function (o) { if (o.card_id != cardId) cc.push(o) });
      state.creditCards = cc;
    },
  },
});

export const { setCredentials, clearCredential, updateProfile, updateAddress, addCreditCard, updateCreditCard, removeCreditCard } = userSlice.actions;

export default userSlice.reducer;

export const selectCurrentUser = (state) => state.user.user;
export const selectCurrentToken = (state) => state.user.token;
export const isUserLogin = (state) => state.user.isLogin;