import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Card, Typography, Row, Col, Divider, Space } from 'antd';
import { SafetyCertificateOutlined } from '@ant-design/icons';


import { usePlaceOrderMutation } from '../../features/api/orderApiSlice';
import { useGetCartMutation } from '../../features/api/cartApiSlice';
import { useClearCartMutation } from '../../features/api/cartApiSlice';
import { placeOrder, setLastOrder } from '../../features/orderSlice';
import { initCart, setCart } from '../../features/cartSlice';
import { selectCurrentUser } from '../../features/userSlice';


import OrderSummary from '../../components/OrderSummary';
import CreditCardList from '../../components/CreditCardList';
import CartItemsList from '../../components/CartItemsList';
import Address from '../../components/Address';
const { Title, Text } = Typography;



const Checkout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const user = useSelector(selectCurrentUser);
  const cart = useSelector(state => state.cart);
  const creditCards = useSelector(state => state.user.creditCards);

  const [placeOrder] = usePlaceOrderMutation();
  const [getCart] = useGetCartMutation();
  const [clearCart] = useClearCartMutation();
  const [selectedCard, setSelectedCard] = useState();

  const handleCardSelect = (cardId) => {
    setSelectedCard(cardId);
  };

  const handleCheckOut = (data) => {
    // Create Order Object
    var order = { ...cart }; // Copy cart data

    // add user data to order
    order.user = user;

    // add credit card data to user.
    if (!selectedCard) {
      setSelectedCard(creditCards[0]);
      order.creditCard = creditCards[0];
    }
    else {
      order.creditCard = selectedCard;
    }

    // Place Order
    placeOrder(order).unwrap()
      .then((response) => {
        dispatch(setLastOrder(response));
        // Clear Cart
        clearCart(user).unwrap()
          .then((response) => {
            getCart(user).unwrap()
              .then((getCartResponse) => {
                dispatch(initCart);
                dispatch(setCart(getCartResponse));
              });
          });


        // Navigate to confirm order
        navigate("/confirmOrder");
      })
      .catch((error) => {
        console.log('place Order Error:', error);
        // TODO: Show error
      })
      ;


  }


  return (
    <>
      <Title level={2}>Checkout ({cart.count} item{cart.count > 1 ? "s" : ""})</Title>
      <Row gutter={[12, 12]}>
        <Col span={16}>
          <Space size="middle" direction="vertical" style={{ display: 'flex' }}>
            <Address title="1 Billing Address" />

            <CreditCardList creditCards={creditCards.filter((c) => c.card_id)} selectedCard={selectedCard} onCardSelect={handleCardSelect} allowSelect />

            <CartItemsList cartItems={cart.items} />

            <Card>
              <Button type="primary" style={{ "width": "100%" }} onClick={handleCheckOut}>
                <SafetyCertificateOutlined />{t('formLabel.placeOrderButton')}
              </Button>
              <Text>By placing your order, you agree to Corporate Profiles Canada's <Link>privacy notice</Link> and <Link>condition of use</Link></Text>
            </Card>
          </Space>
        </Col>
        <Col span={8}>
          <Card>
            <Button type="primary" style={{ "width": "100%" }} onClick={handleCheckOut}>
              <SafetyCertificateOutlined />{t('formLabel.placeOrderButton')}
            </Button>
            <Text>By placing your order, you agree to Corporate Profiles Canada's <Link>privacy notice</Link> and <Link>condition of use</Link></Text>
            <Divider></Divider>
            <OrderSummary order={cart} />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Checkout;
