import React from 'react';
import { Typography, Row, Col } from 'antd';
import { BankOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { currencyFormatter } from '../const/formConstants';

const { Text } = Typography;

const CompanyInfo = ({ company, showPrice }) => {
    return (<>
        <Row>
            <Col span={1}>
                <BankOutlined />
            </Col>
            <Col span={20}>
                <Text strong type="success">
                    {company.entityName}
                </Text><br />
                <Text type="secondary">
                    {company.businessNumber} {company.confirmedEntityType}
                </Text>
            </Col>
            {showPrice &&
                <Col span={2}>
                    <Text type="danger">{currencyFormatter.format(company.Price)}</Text>
                </Col>
            }
        </Row>
        <Row>
            <Col span={1}>
                <EnvironmentOutlined />
            </Col>
            <Col span={23}>
                {company.address?.addressLine1}{company.address?.addressLine2 ? ` ${company.address?.addressLine2}` : ''}{company.address?.addressLine3 ? ` ${company.address?.addressLine3}` : ''} <br />
                {company.address?.city ? company.address?.city : ''} &nbsp;
                {company.address?.provinceState}
                <br />{company.address?.country} &nbsp;
                {company.address?.code}
            </Col>
        </Row>
    </>);

}

export default CompanyInfo;