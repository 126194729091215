import React, { useState } from 'react';
import { Row, Col, Form, Input, Button, Typography, Alert, Anchor } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formInputLayout } from '../const/formConstants';
import authService from '../services/AuthService';

const { Title, Paragraph } = Typography;

const ResetPasswordForm  = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const { register, handleSubmit, errors } = useForm();

    const onSubmitPassword = (data) => {
        // Handle login with email and password
    
          // toDo: enable login against Id Server, config in /AuthConfig.js
          // await authService.login(from.pathname);
    
        console.log('Reset Password:', data.password);
      };     

    return (
        <div>
            <Row justify='center'>
                <Col span={12}>
                    <Title level={2}>{t('resetPasswordForm.title')}</Title>
                </Col>
            </Row>            
            <Form onFinish={handleSubmit(onSubmitPassword)} {...formInputLayout}>
            <Row justify='center'>
                <Col span={12}>        
                    <Form.Item
                        label={t('formLabel.passwordInput')}
                        name="password"
                        rules={[{ required: true, message: t('formErrorMsg.passwordRequired') }]}
                    >
                        <Input.Password />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify='center'>
                <Col span={12}>        
                    <Form.Item
                        label={t('formLabel.confirmPasswordInput')}
                        name="confimPassword"
                        rules={[{ required: true, message: t('formErrorMsg.passwordRequired') }]}
                    >
                        <Input.Password />
                    </Form.Item>
                </Col>
            </Row>            
            <Row>
                <Col span={2} offset={11}>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                    {t('formLabel.resetPasswordButton')}
                    </Button>
                </Form.Item>
                </Col>                
            </Row>
            </Form>            
        </div>
    );

};

export default ResetPasswordForm ;